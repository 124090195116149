import React from 'react'
import CTAButton from "./CTAButton"

const ExpertsSection = (props) => {
  const { title, text, buttonLink, buttonText, image } = props
  return (
      <>
        <div className="columns level-left">
          <div className="column is-5">
            <div className="block mb-10">
              <h2 className="title is-h2 has-text-weight-bold">{title}</h2>
            </div>
            <div className="block">
              <div className="subtitle is-size-4">{text}</div>
            </div>
            <CTAButton link={buttonLink} text={buttonText} />
          </div>
          <div className="column is-7">
            <img src={image.publicURL} alt="Advisors"/>
          </div>
        </div>
      </>
  )
}

export default ExpertsSection