import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import CTAButton from "../components/CTAButton"
import SimpleHeader from "../components/SimpleHeader";
import ViewPricingSection from "../components/ViewPricingSection";
import ExpertsSection from "../components/ExpertsSection";


// eslint-disable-next-line
export const CompensationConsultingServicesPageTemplate = ({ intro, features, closing, pricing, experts }) => {
  return (
    <>
      {/*INTRO START*/}
      <section className="section is-small no-padding-bottom">
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Compensation Consulting Services - Kamsa</title>
            <meta property="og:title" content="Compensation Consulting Services - Kamsa" />
            <meta name="description" content="Kamsa is a compensation management system that combines global salary data & expert support from compensation consultants for simplified compensation planning." />
          </Helmet>
        </div>
      </section>
      {/*INTRO START*/}
      <section className="section section--gradient is-small">
          <div className="container">
            <div className="columns">
              <SimpleHeader
                heading={intro.header}
                header={intro.subheader}
                subheader={intro.body}
                columnSize={'is-7'}
                isPageHeader
              />
            </div>
          </div>
      </section>
      {/*INTRO END*/}
      {/*FEATURES START*/}
      <section className="section is-medium no-padding-top">
        <div className="container">
            { features.map((value, index) =>
              <div key={index} className={`columns is-centered ${!!(index % 2) ? 'level-right' : 'level-left'}`}>
                <div className="column is-6">
                  <img className="features--image" src={value.image.publicURL} alt={value.title} />
                </div>
                <div className="column is-4">
                  <div className="columns is-mobile">
                    <div className="column">
                      <div className="block">
                        <h2 className="title is-2 has-text-weight-bold m-0">{value.title}</h2>
                      </div>
                      <div className="block">
                        <div className="subtitle is-size-4">{value.description}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
      </section>
      {/*FEATURES END*/}
      {/*CLOSING START*/}
      <section className="section is-medium no-padding-top">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10">
            <div className="block">
              <h2 className="title is-2 has-text-weight-bold">{closing.title}</h2>
            </div>
            <div className="block">
              <div className="subtitle is-size-4">
                {closing.subtitle}
              </div>
            </div>
            <div className={"block"}>
              <div className='content'>
                <ul>
                  {closing.content.map((feature, index) => {
                    return(
                      <li className={'my-4 is-size-5'} key={index}>
                        <ReactMarkdown>{feature.text}</ReactMarkdown>
                      </li>
                    )
                  })}
                </ul>
                </div>
              </div>
              <div className="block has-text-centered">
                <CTAButton
                  link={closing.buttonLink}
                  text={closing.buttonText}
                  noIcon={true}
                  buttonSize={'large'}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*CLOSING END*/}
      {/*EXPERTS START*/}
      <section className="section section--primary is-small">
        <div className="container">
          <ExpertsSection
            title={experts.title}
            text={experts.text}
            buttonLink={experts.buttonLink}
            buttonText={experts.buttonText}
            image={experts.image}
          />
        </div>
      </section>
      {/*EXPERTS END*/}
      {/*PRICING START*/}
      <section className="section section--gradient is-medium">
          <ViewPricingSection
              title={pricing.title}
              subtitle={pricing.subtitle}
              buttonLink={pricing.buttonLink}
              buttonText={pricing.buttonText}
          />
      </section>
      {/*PRICING END*/}
    </>
  );
};

CompensationConsultingServicesPageTemplate.propTypes = {
  ccsPageIntro: PropTypes.shape({
    header: PropTypes.string,
    subheader: PropTypes.string,
    body: PropTypes.string,
  }),
  ccsPageFeatures: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  ccsPageClosing: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    content: PropTypes.shape({
      text: PropTypes.string,
    }),
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  ccsPagePricing: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  ccsPageExperts: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  })
};

const CompensationConsultingServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <CompensationConsultingServicesPageTemplate
        intro={frontmatter.ccsPageIntro}
        features={frontmatter.ccsPageFeatures}
        closing={frontmatter.ccsPageClosing}
        pricing={frontmatter.ccsPagePricing}
        experts={frontmatter.ccsPageExperts}
      />
    </Layout>
  );
};

CompensationConsultingServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CompensationConsultingServicesPage;

export const CompensationConsultingServicesQuery = graphql`
  query CompensationConsultingServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ccsPageIntro {
            header
            subheader
            body
        }
        ccsPageFeatures {
          title
          description
          buttonLink
          buttonText
          image {
            publicURL
          }
        }
        ccsPageClosing {
          title
          subtitle
          content {
            text
          }
          buttonLink
          buttonText
        }
        ccsPagePricing {
          title
          subtitle
          buttonLink
          buttonText
        }
        ccsPageExperts {
          title
          text
          buttonLink
          buttonText
          image {
            publicURL
          }
        }
      }
    }
  }
`;
